<template>
  <div>
    <apexchart height="820" type="bar" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "BarChart",
  props: {
    barChartData: { type: Array, required: true }
  },
  components: {
    apexchart: VueApexCharts
  },
  data: function () {
    return {
      seriesData: [],
      categoryData: []
    };
  },
  watch: {
    barChartData: {
      handler(val) {
        this.seriesData = val.map(ele => ele.budget);
        this.categoryData = val.map(ele => {
          if (ele.category === "संस्थागत विकास, सेवा प्रवाह र सुशासन" || ele.category === "वातावरण तथा विपद व्यवस्थापन") {
            return ele.category.match(/^(\S+? \S+?) ([\s\S]+?)$/).slice(1, 3);
          }
          return ele.category
        });
      },
      immediate: true
    }
  },
  computed: {
    series() {
      return [
        {
          name: "series-1",
          data: this.seriesData
        }
      ];
    },
    options() {
      return {
        chart: {
          type: 'bar',
          width: "100%",
          height: 820,
          toolbar: {
            show: false
          }
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: this.categoryData,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false
          }
        },
        yaxis: {
          opposite: true,
          labels: {
            maxWidth: "auto",
            style: {
              colors: ["#BEBFCB"],
              fontSize: "40px"
            }
          }
        },
        fill: {
          colors: ["#0E5CAD", "#EA5455", "#0396FF", "#7367F0", "#D939CD"],
          type: "gradient",
          gradient: {
            shade: "light",
            gradientToColors: [
              "#79f1a4",
              "#feb692",
              "#abdcff",
              "#ce9ffc",
              "#f6ceec"
            ],
            shadeIntensity: 1,
            type: "diagonal1",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
            borderRadius: 10,
            barHeight: "70%",
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 0,
                  color: "pink"
                }
              ],
              backgroundBarColors: "#272A52",
              // backgroundBarOpacity: 0.5,
              backgroundBarRadius: 10
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        responsive: [
          {
            breakpoint: 3200,
            options: {
              chart: {
                height: 300
              },
              yaxis: {
                opposite: true,
                labels: {
                  style: {
                    colors: ["#BEBFCB"],
                    fontSize: "14px"
                  }
                }
              },
              plotOptions: {
                bar: {
                  borderRadius: 4,
                  colors: {
                    backgroundBarRadius: 4
                  }
                }
              }
            }
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss">
.apexcharts-gridline {
  display: none;
}

.annual-budget-chart__chart {
  .apexcharts-text.apexcharts-yaxis-label {
    transform: translate(16px, 15px);
    @media screen and (max-width: 3199px) {
      transform: translateY(5px);
    }
  }
}

/* rect {
  width: 200px!important;
} */
</style>
